$(document).ready(function() {
  App = (function() {
    //Error handler
    window.onerror = function(message, url, lineNumber, colNumber, error) {
      errorHandler(message, url, lineNumber, colNumber, error);
    };

    /**
     * Catch DOM, its more efficient.
     */
    var $overlay = $(".overlay");
    var $xcsrf = $('meta[name="csrf-token"]');
    var $token = $('meta[name="csrf-token"]').attr("content");
    var activeFilters = undefined;
    var paginator = undefined;

    function errorHandler(message, url, lineNumber, colNumber, error) {
      if (APP_CONFIG.ENV != "production" && APP_CONFIG.DEBUG) {
        return false;
      }

      return true;
    }

    /**
     * Init the module
     */
    _ajaxSetup();

    /**
     * Setup ajax calls
     */
    function _ajaxSetup() {
      $.ajaxSetup({
        headers: {
          "X-CSRF-TOKEN": $xcsrf.attr("content")
        }
      });
    }

    /**
     * Make a ajax call
     */
    function makeAjaxCall(url, type, data, block, func) {
      var base_url = url.split("?")[0];
      var old_string = url.split("?")[1] ? url.split("?")[1] : undefined;
      var paginator = undefined;
      var activeFilters = undefined;
      var query = undefined;

      if (old_string != null) {
        if (query == null) {
          query = "?" + $.param(old_string);
        } else {
          query += "&" + $.param(old_string);
        }
        // console.log("old_string != null, query: " + query);
      }
      if (typeof App.paginator != "undefined") {
        if (query == null) {
          query = "?" + $.param(App.paginator);
        } else {
          query += "&" + $.param(App.paginator);
        }
        // console.log("paginator != null, query: " + query);
      }
      if (typeof App.activeFilters != "undefined") {
        if (query == null) {
          query = "?" + $.param(App.activeFilters);
        } else {
          query += "&" + $.param(App.activeFilters);
        }
        // console.log("filters != null,query: " + query);
      }
      // console.log("query: " + query);

      if (query != null) {
        url = base_url + query;
      } else {
        url = base_url;
      }

      // console.log(url);

      var config = {
        url: APP_CONFIG.SERVICE_URL + url,
        dataType: "json"
      };
      config.data = data === undefined ? {} : data;

      switch (type) {
        case "patch":
          config.type = "post";
          config.data._method = "patch";
          break;
        case "put":
          config.type = "post";
          config.data._method = "put";
          break;
        case "delete":
          config.type = "post";
          config.data._method = "delete";
          break;
        default:
          config.type = type;
          break;
      }

      _blockpage(block);
      $.ajax(config)
        .done(function(response) {
          _blockpage(false);
          return func(response);
        })
        .fail(function(response) {
          _blockpage(false);
          notify("error", "Ha ocurrido un error", parseApiError(response));
        });
    }

    /**
     * Parse Api Errors
     */
    function parseApiError(response) {
      var value;
      switch (response.status) {
        // case 400:
        // value = response.message;
        // break;
        default:
          value = response.responseJSON.errors
            ? response.responseJSON.errors
            : response.responseJSON.message
            ? response.responseJSON.message
            : "Ha ocurrido un error, por favor intente mas tarde";
          break;
      }

      return value;
    }

    function notify(type, title, text) {
      new PNotify({
        title: title,
        text: text,
        type: type,
        delay: 3000,
        nonblock: {
          nonblock: true
        },
        styling: "bootstrap3"
      });
    }

    function _blockpage(show) {
      if (show) {
        $overlay.show();
      } else {
        $overlay.hide();
      }
    }

    function exists(el) {
      return $(el).length > 0 ? true : false;
    }

    function getActiveFilters() {
      var $inputs = $(".filters input, .filters select");
      var data = {};
      $inputs.each(function(index) {
        var name = $(this).attr("name");
        var value = $(this).val();
        if (value) {
          if ($(this).prop("type") === "checkbox") {
            if ($(this).prop("checked")) data[name] = value;
          } else {
            data[name] = value;
          }
        }
      });

      var retVal = $.isEmptyObject(data) ? undefined : data;

      return retVal;
    }

    /**
     * Expose Properties and Methods
     */
    return {
      errorHandler: errorHandler,
      makeAjaxCall: makeAjaxCall,
      getActiveFilters: getActiveFilters,
      activeFilters: activeFilters,
      paginator: paginator,
      notify: notify,
      exists: exists,
      $token: $token
    };
  })();
});

$(document).ready(function() {
  App.events = (function() {
    /**
     * Stacked events
     */
    var events = {};

    /**
     * Suscribe to event
     */
    function suscribe(eventName, fn) {
      events[eventName] = events[eventName] || [];
      events[eventName].push(fn);
    }

    /**
     * Unsuscribe to event
     */
    function unsuscribe(eventName, fn) {
      if (events[eventName]) {
        for (var i = 0; i < events[eventName].length; i++) {
          if (events[eventName][i] === fn) {
            events[eventName].splice(i, 1);
            break;
          }
        }
      }
    }

    /**
     * Fire event
     */
    function fire(eventName, data) {
      if (events[eventName]) {
        events[eventName].forEach(function(fn) {
          fn(data);
        });
      }
    }

    /**
     * Expose Properties and Methods
     */
    return {
      suscribe: suscribe,
      unsuscribe: unsuscribe,
      fire: fire
    };
  })();
});

$(document).ready(function() {
  (function() {
    /***********************************************************************
     * **************************Init Basic Funcions*************************
     ***********************************************************************/

    /**
     * Catch DOM, its more efficient.
     */
    var $window = $(document);
    var $formsValidateElems = $("form.validate");
    var $alerts = $(".alert-autoclose");
    var $collapsedPanels = $(".collapsed");
    // var $source = $("#notifications-template").html();
    // var $container = $("#notifications-container");
    var $submitButton = $('button[type="submit"].submit');
    // App.events.suscribe("NotificationsObtained", _renderNotifications);

    /**
     * Init Module.
     */
    _validationForms();
    _toolTips();
    _initModals();
    _initSearch();
    _initPaginator();
    _initBulkActions();
    _initAutoCloseAlerts();
    _initCollapsedPanels();
    // _initNotifications();

    function _initCollapsedPanels() {
      $collapsedPanels.css("height", "auto");
      $collapsedPanels.find(".x_content").css("display", "none");
      $collapsedPanels.find("i").toggleClass("fa-chevron-up fa-chevron-down");
    }

    // function _renderNotifications(data) {
    //   var template = Handlebars.compile($source);
    //   $container.html(template(data));
    // }

    /**
     * Get Notifications
     */
    // function _initNotifications() {
    //   App.makeAjaxCall(
    //     "admin/notifications.json",
    //     "get",
    //     undefined,
    //     false,
    //     function(response) {
    //       App.events.fire("NotificationsObtained", response);
    //     }
    //   );

    //   setInterval(function() {
    //     App.makeAjaxCall(
    //       "admin/notifications.json",
    //       "get",
    //       undefined,
    //       false,
    //       function(response) {
    //         App.events.fire("NotificationsObtained", response);
    //       }
    //     );
    //   }, 50000);
    // }

    function _initAutoCloseAlerts() {
      $($alerts)
        .fadeTo(5000, 500)
        .slideUp(500, function() {
          $($alerts).slideUp(500);
        });
    }

    function _initBulkActions() {
      // Table
      var checkState = "";
      $window.on("change", ".bulk_action .control", function() {
        checkState = "";
        var $checkbox = $(this).find("input[name='table_records']");
        if ($checkbox.prop("checked")) {
          $checkbox.attr("checked", true);
          $checkbox
            .parent()
            .parent()
            .parent()
            .addClass("selected");
          _countChecked();
        } else {
          $checkbox.attr("checked", false);
          $checkbox
            .parent()
            .parent()
            .parent()
            .removeClass("selected");
          _countChecked();
        }
      });

      $window.on("change", ".bulk_action .headings #check-all", function() {
        var $checkbox = $(this).find("input");
        if ($checkbox.prop("checked")) {
          $checkbox.attr("checked", true);
          checkState = "all";
          _countChecked();
        } else {
          $checkbox.attr("checked", false);
          checkState = "none";
          _countChecked();
        }
      });

      $window.on("click", ".bulk-actions .btn-success", function() {
        var $button = $(this);
        var target = $button.data("target");
        var $option = $(".bulk_options").children("option:selected");

        $("#newValue").val(undefined);

        if ($option.data("href") !== undefined) {
          App.makeAjaxCall($option.data("href"), "get", {}, true, function(
            response
          ) {
            var $modal = $(target).modal();
            var html = buildSelectForModal(response);
            $(target)
              .find("#bulk-content")
              .html(html);
            //bind ckick event to btn-ok of modal
            $modal.on("click", ".btn-ok", function() {
              $modal.modal("hide");
              _runBulkActions($button);
            });

            //unbind event click when the modal is hidding
            $modal.on("hidden.bs.modal", function() {
              $(this).off("click", ".btn-ok");
            });
          });
        } else {
          var $modal = $(target).modal();
          $(target)
            .find("#bulk-content")
            .html("<p>¿Esta seguro que deseas realizar esta acción?</p>");
          //bind ckick event to btn-ok of modal
          $modal.on("click", ".btn-ok", function() {
            $modal.modal("hide");
            _runBulkActions($button);
          });

          //unbind event click when the modal is hidding
          $modal.on("hidden.bs.modal", function() {
            $(this).off("click", ".btn-ok");
          });
        }
      });

      function _runBulkActions($button) {
        var href = $button.data("href");
        var event = $button.data("event");
        var actionSelected = $(".bulk_options").val();
        var $items = $(".bulk_action input[name='table_records']:checked");
        var selectedItems = [];

        $items.each(function() {
          var $item = $(this);
          selectedItems.push($item.data("id"));
        });

        if (App.paginator) {
          href = href + "?page=" + App.paginator.page;
        }

        var parameters = {
          items: selectedItems,
          action: actionSelected
        };

        if ($("#newValue").val() !== undefined) {
          parameters.newValue = $("#newValue").val();
        }

        App.makeAjaxCall(href, "post", parameters, true, function(response) {
          //fire user view refresh event
          App.events.fire(event, response);
        });
      }

      function _countChecked() {
        var $checkboxes = $(".bulk_action input[name='table_records']");
        var $parents = $checkboxes
          .parent()
          .parent()
          .parent();
        if (checkState === "all") {
          $checkboxes.prop("checked", true).attr("checked", true);
          $parents.addClass("selected");
        }
        if (checkState === "none") {
          $checkboxes.prop("checked", false).attr("checked", false);
          $parents.removeClass("selected");
        }

        var checkCount = $(".bulk_action input[name='table_records']:checked")
          .length;

        if (checkCount) {
          $(".column-title").hide();
          $(".bulk-actions").show();
          $(".action-cnt").html(checkCount);
        } else {
          $(".column-title").show();
          $(".bulk-actions").hide();
        }
      }
    }

    function buildSelectForModal(response) {
      var $html = "<p>Seleccione una nueva opcion</p>";
      $html +=
        response.type == "multiple"
          ? '<select multiple id="newValue" class="form-control">'
          : '<select id="newValue" class="form-control">';
      $.each(response.data, function(key, item) {
        $html += '<option value="' + item.id + '">' + item.value + "</option>";
      });
      $html += "</select>";

      return $html;
    }

    /**
     * Load Forms Validations
     */
    function _validationForms() {
      $formsValidateElems
        .validator({
          disable: false
        })
        .on("submit", function(e) {
          if (!e.isDefaultPrevented()) {
            $submitButton.prop("disabled", true);
          }
        });
    }

    /**
     * Init all seacrh boxes
     */
    function _initPaginator() {
      $window.on("click", ".pagination-nav", function(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        App.activeFilters = App.getActiveFilters();
        var $button = $(this);
        var href = $button.data("href");
        if (href.split("?")[1]) {
          App.paginator = {
            page: href.split("?")[1].split("=")[1]
          };

          href = href.split("?")[0];
        }
        var event = $button.data("event");
        App.makeAjaxCall(href, "get", undefined, true, function(response) {
          //fire user view refresh event
          App.events.fire(event, response);
        });
      });
    }

    /**
     * Init all search boxes
     */
    function _initSearch() {
      var $btnSearch = $(".btn-search");
      var $btnClean = $(".clean-search");
      var $inputs = $(".filters input, .filters select");

      $btnSearch.on("click", function() {
        App.paginator = undefined;
        App.activeFilters = App.getActiveFilters();
        if (App.activeFilters) {
          var href = $btnSearch.data("href");
          var event = $btnSearch.data("event");
          App.makeAjaxCall(href, "get", undefined, true, function(response) {
            //fire user view refresh event
            App.events.fire(event, response);
          });
        } else {
          App.activeFilters = undefined;
          App.notify(
            "warning",
            "Ningun filtro aplicado",
            "No ha seleccionado ningun filtro"
          );
        }
      });

      $btnClean.on("click", function() {
        App.paginator = undefined;
        App.activeFilters = undefined;
        var href = $btnClean.data("href");
        var event = $btnSearch.data("event");
        App.makeAjaxCall(href, "get", undefined, true, function(response) {
          //fire user view refresh event
          $inputs.val(null);
          if (App.exists($autocomplete)) {
            $autocomplete.trigger("chosen:updated");
          }
          App.events.fire(event, response);
        });
      });
    }

    /**
     * Load Tooltips elemts
     */
    function _toolTips() {
      $("body").tooltip({
        selector: '[data-toggle="tooltip"]'
      });
    }

    /**
     * Init all Ajax Modals
     */
    function _initModals() {
      //Delegated click
      $window.on("click", ".btn-ajax-modal", function() {
        var $inputs = $(".filters input");
        var $button = $(this);
        var target = $button.data("target");
        var href = $button.data("href");
        var method = $button.data("method");
        var event = $button.data("event");
        var $modal = $(target).modal();

        //bind ckick event to btn-ok of modal
        $modal.on("click", ".btn-ok", function() {
          $modal.modal("hide");
          if (method === "delete") {
            App.paginator = undefined;
            $inputs.val(null);
          }
          App.makeAjaxCall(href, method, App.paginator, true, function(
            response
          ) {
            //fire user view refresh event
            App.events.fire(event, response);
          });
        });

        //unbind event click when the modal is hidding
        $modal.on("hidden.bs.modal", function() {
          $(this).off("click", ".btn-ok");
        });
      });
    }

    /***********************************************************************
     * **************************Init Plugins *******************************
     ***********************************************************************/

    /**
     * Catch DOM, its more efficient.
     */
    var $datePickerRange = $(".date-range-picker");
    var $selectTokenize = $(".tokenize");
    var $tokenize2 = $(".tokenize2");
    var $autocomplete = $(".chosen");
    var $tags = $(".tokenize-tags");
    var $select = $(".multiselect");

    /**
     * Init Module.
     */
    _initDatesPickers();
    _initMultipleSelect();
    _initMultiSelect();
    _initCKEditor();
    _initTokenize2();
    _initTags();
    _initMasks();
    _initAutocomplete();

    function _initDatesPickers() {
      if (App.exists($datePickerRange)) {
        $datePickerRange.daterangepicker({
          locale: {
            format: "DD-MM-YYYY",
            separator: " @ ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Desde",
            toLabel: "Hasta"
          },
          opens: "left",
          autoUpdateInput: false
        });

        $(".date-range-picker").on("apply.daterangepicker", function(
          ev,
          picker
        ) {
          $(this).val(
            picker.startDate.format("DD-MM-YYYY") +
              " @ " +
              picker.endDate.format("DD-MM-YYYY")
          );
        });

        $(".date-range-picker").on("cancel.daterangepicker", function(
          ev,
          picker
        ) {
          $(this).val("");
        });
      }
    }

    function _initMultipleSelect() {
      if (App.exists($selectTokenize)) {
        $selectTokenize.tokenize();
      }
    }

    /**
     * Init CKEditor
     */
    function _initCKEditor() {
      if (App.exists(".cke")) {
        $elements = $(".cke");
        $.each($elements, function(index, value) {
          CKEDITOR.replace($(this).attr("name"));
          CKEDITOR.config.removePlugins = "image";
        });
      }
    }

    function _initTags() {
      if (App.exists($tags)) {
        $tags.tokenize2({
          tokensAllowCustom: false,
          placeholder: "Escriba algo para empezar...",
          dataSource: APP_CONFIG.SERVICE_URL + "admin/tags.json"
        });
      }
    }

    function _initTokenize2() {
      if (App.exists($tokenize2)) {
        $tokenize2.tokenize2({
          dataSource: "select",
          tokensAllowCustom: false,
          placeholder: "Escriba algo para empezar..."
        });
      }
    }

    function _initMultiSelect() {
      if (App.exists($select)) {
        $select.multiSelect();
      }
    }

    function _initAutocomplete() {
      if (App.exists($autocomplete)) {
        $autocomplete.chosen();
      }
    }

    function _initMasks() {
      $(".mask").each(function() {
        $(this).inputmask($(this).data("mask"));
        $("form").validator("update");
      });
    }
  })();
});
